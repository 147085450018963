import React from 'react'
import PropTypes from 'prop-types'
import { Box, Label, Input, Button, Message, Spinner } from 'theme-ui'
import Cookies from 'universal-cookie'
import useSiteMetadata from '@helpers/useSiteMetadata'

const ContactForm = ({ handleSubmit, submitting, success }) => {
  const { topic } = useSiteMetadata()
  
  const cookies = new Cookies();

  let googleClickId = ''  
  if(cookies.get('gclid') && cookies.get('gclid') !== 'undefined') {
    googleClickId = cookies.get('gclid')
  }

  let addlBrands = ''
  if(cookies.get('addlBrands') && cookies.get('addlBrands') !== 'undefined') {
    addlBrands = cookies.get('addlBrands')    
  }
  // console.log("addlBrands: ", addlBrands)

  return (
    <form onSubmit={handleSubmit}>
      
      <input type="hidden" name="xnQsjsdp" value="1ab2fd4914d0fe4d618826a3078a8e655dba562b93c64793bdb85d05a7e61164" />
      <input type="hidden" name="xmIwtLD" value="3a3930c139b8556a2b11808b84663e331410c00e4a063009da54c7330828a9ba" />
      <input type="hidden" name="actionType" value="TGVhZHM=" />
      <input type="hidden" name="LEADCF38" value={googleClickId} /> {/* Google Click ID */}
      <input type="hidden" name="Lead Source" value="Web" />
      <input type="hidden" name="LEADCF4" value={topic} />
      <input type="hidden" name="LEADCF35" value={addlBrands} /> {/* Additional Brands */}      
      
      {success && (
        <Message variant='success'>
          Thanks for your interest, we'll be in touch soon.
        </Message>
      )}
      
      <Box variant='forms.row'>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-first-name'>First Name</Label>
          <Input 
            type='text' 
            id='contact-form-first-name' 
            name='firstName' 
            placeholder='First Name'
            required 
          />
        </Box>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-last-name'>Last Name</Label>
          <Input 
            type='text' 
            id='contact-form-last-name' 
            name='lastName' 
            placeholder='Last Name'
            required 
          />
        </Box>
      </Box>
      <Box variant='forms.row'>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-email'>Email</Label>
          <Input
            type='email'            
            id='contact-form-email'
            name='Email'
            placeholder='Email'
            required
          />
        </Box>
        <Box variant='forms.column'>
          <Label htmlFor='contact-form-phone'>Phone</Label>
          <Input
            type='tel'            
            id='contact-form-phone'
            name='Phone'
            placeholder='Phone'
            required
          />
        </Box>
      </Box>
      {/* <Box variant='forms.row'>
        <Label htmlFor='contact-form-subject'>Subject</Label>
        <Input type='text' id='contact-form-subject' name='subject' required />
      </Box>
      <Box variant='forms.row'>
        <Label htmlFor='contact-form-message'>Comments, Questions, etc.</Label>
        <Textarea name='message' id='contact-form-message' />
      </Box> */}
      <Button
        variant={success || submitting ? 'disabled' : 'primary'}
        disabled={success || submitting}
        type='submit'
        required
      >
        Request Information {submitting && <Spinner size='20' />}
      </Button>
    </form>  
  )
}

export default ContactForm

ContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}
